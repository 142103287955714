import React from "react";
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./App.css"; // Assuming you have a separate CSS file for styles
import vision from "./Assets/vision2.png";
import Values from "./Assets/values.png";
import Goals from "./Assets/goals.png";
import ScrollTrigger from 'react-scroll-trigger';

const Card = () => {

  const [isInViewport, setIsInViewport] = useState(false);

  const handleScroll = (visible) => {
    setIsInViewport(visible);
  };
  return (
    <ScrollTrigger onEnter={() => handleScroll(true)} onExit={() => handleScroll(false)}>
    <div className={`animated-element ${isInViewport ? 'active' : ''}`}>
    <div className="text-light">
      <Container>
        <Row xs={1} sm={2} md={3} className="g-4">
          <Col>
            <div className="card shadow" style={{ backgroundColor: "#1A2E3A" }}>
              <img
                className="card-img-top"
                src={vision}
                alt="Card image cap"
              />
              <div className="card-body">
                <h5 className="card-title-vision">Vision</h5>
                <p className="card-text">
                To become the best leather and clothing brand that presents Africa for the rest of the World and deliver our products all over. 
  
                </p>
              </div>
            </div>
          </Col>
          <Col>
            <div className="card shadow" style={{ backgroundColor: "#1A2E3A" }}>
              <img
                className="card-img-top"
                src={Values}
                alt="Card image cap"
              />
              <div className="card-body">
                <h5 className="card-title-value">Values</h5>
                <p className="card-text">
                High quality, Accessibility, Africanism, Convenience and Consistency 
                </p>
              </div>
            </div>
          </Col>
          <Col>
            <div className="card shadow" style={{ backgroundColor: "#1A2E3A" }}>
              <div className="fullcard">
              <img
                className="card-img-top"
                src={Goals}
                alt="Card image cap"
              />
              <div className="card-body">
                <h5 className="card-title-goal">Goals</h5>
                <p className="card-text">
                Produces goods that tell stories of brave women supporting each other and Focus on products that customers like most
                </p>
              </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    </div>
    </ScrollTrigger>
  );
};

export default Card;
