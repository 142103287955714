import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import { useState } from "react";
import {
  MDBContainer,
  MDBCol,
  MDBRow,
} from 'mdb-react-ui-kit';
import Container from '@mui/material/Container';
import bag from './Assets/bag.jpg';
import shoe from './Assets/shoe .png';
import cloth from './Assets/cloth2.png';
import newcloth from './Assets/newcloth.jpg'
import clothes from './Assets/clothh.jpg'
import shoes from './Assets/shoes.jpg'
// import cloth from "./Assets/Asset 5.png"

export const Grid = () => {
  const [isInViewport, setIsInViewport] = useState(false);

  const handleScroll = (visible) => {
    setIsInViewport(visible);
  };
  return (
    <ScrollTrigger onEnter={() => handleScroll(true)} onExit={() => handleScroll(false)}>
    <div className={`animated-element ${isInViewport ? 'active' : ''}`}>
    <div className='grid-container'>
     <Container maxWidth="lg">
   
   <div className='grid_style'>
    <MDBRow>
      <MDBCol lg={6} md={12} className='mb-4 mb-lg-0'>
        
<div className='img-bag'>
<img
          src={clothes}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Boat on Calm Water'
        />
  <div className='img-cloth-text' 
  >
    Clothes
  </div>
</div>

<div className='img-shoe'>
  <img
    src={shoes}
    className='w-100 shadow-1-strong rounded mb-4'
    alt='Wintry Mountain Landscape'
  />
  <div className='img-shoe-text' 
  >
    Shoes
  </div>
</div>
      </MDBCol>

      <MDBCol lg={6} className='mb-4 mb-lg-0'>
       
<div className='img-cloth'>
<img
          src={newcloth}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Mountains in the Clouds'
        />
  <div className='img-bag-text' 
  >
    Bags
  </div>
</div>
       
      </MDBCol>
     
       

    </MDBRow>
    </div>
    </Container>
    
    </div>
    </div>
    </ScrollTrigger>
  );
}