import React from 'react'
import "./App.css";


export default function MIssion() {


  return (

   
     
          <section id="mission" class="mission" >
      <div class="container" data-aos="fade-up">
        <div class="row align-items-center">
          <div class="col-md-6">
            
          </div>
    
          <div class="col-md-6 mt-5 mt-md-0 ps-md-5 text-white content">
            <h2 class="section_title">Mission</h2>
            <div class="firstParagraph">
            <p  class="mt-3">
             
            To provide products that represent all the ladies out there going after their dreams, 
struggling to balance their lives, who achieved their goals but missing a piece, appreciate 
them every single day because they are Queens, warriors, yekeberu, as we call them “KIBIRT
     

            </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    
   
  )
}
