import React from 'react';
import { useState, useEffect } from 'react';
import "./App.css";
import africa from "./Assets/africa.jpg";
import bag from "./Assets/bag.jpg";
import bag2 from './Assets/brown.png';


const Hero = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [bag, bag2];

  useEffect(() => {
  
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    
    <div className='hero'>
    <div className="container">
      <div className="row justify-content-between align-items-center">
        <div className="col-lg-6 col-md-6 col-sm-6 py-5">
       
          <h5 className="shop-Text display-2">
        
          “Show your  <span className="stylish-text"> Diva</span> Within”
          </h5>
          <p className="text-white">
          made in Ethiopia
          </p>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 py-5">
          <img
            src={africa}  className="img-fluid image"
            alt="Nature"
          />
      <img
      src={images[currentImageIndex]}
      className="secound-bag"
      alt={`Leather Bag ${currentImageIndex + 1}`}
    />
          
        </div>

      </div>
      <p className="text-a" >“አንቺ ዉድ ነሽ፤ አንቺ ጠንካራ ነሽ፤ አንቺ የከበርሽ ነሽ…….”</p>
    </div>
    </div>
  );
};

export default Hero;
